<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-stepper v-model="getDeviceStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getDeviceError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('cars:carInformation') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getDeviceError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('cars:carExtraInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <!-- MODAL CONTACT -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalContact.open"
                                    @close="closeModalContact"
                                >
                                    <AEContentContact slot="AEContent" />
                                    <!-- <ButtonsContact
                                        slot="buttons"
                                        :action="'create'"
                                        @closeModal="closeModalContact"
                                    /> -->
                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewContact()"
                                            :disabled="disabled"
                                            @closeModal="closeModalContact"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL BRANCHES -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalBranches.open"
                                    @close="closeModalBranches"
                                >
                                    <AEContentBranches slot="AEContent" />
                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewBranches()"
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL REPRESANTIVE -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalRepresentative.open"
                                    @close="closeModalRepresentative"
                                >
                                    <AEContentRepresentative slot="AEContent" />

                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewRepresentative()"
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <v-row>
                                    <v-col
                                        cols="8"
                                        xl="8"
                                        lg="8"
                                        sm="12"
                                        class="padding-r"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                md="4"
                                                class="pt-0"
                                            >
                                                <v-text-field
                                                    :disabled="editSn"
                                                    dense
                                                    v-model="getDeviceModal.sn"
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.sn
                                                    "
                                                    :label="`${$t(
                                                        'devices:sn'
                                                    )}*`"
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-barcode</v-icon
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="4"
                                                md="4"
                                                class="pt-0"
                                            ></v-col>
                                                   <v-col
                                                cols="6"
                                                md="4"
                                                class="pt-0"
                                            >
                                                <v-text-field
                                                    dense
                                                    v-model="getDeviceModal.identificationNumber"
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.identificationNumber
                                                    "
                                                    :label="`${$t(
                                                        'devices:identificationNumber'
                                                    )}*`"
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-identifier</v-icon
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="2"
                                                md="12"
                                                class="pt-0"
                                            >
                                                <Separator
                                                    :text="$t('devices:device')"
                                                /> </v-col
                                        ></v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-combobox
                                                    dense
                                                    v-model="
                                                        getDeviceModal.model
                                                    "
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.model
                                                    "
                                                    :hint="`${$t(
                                                        'global:startWriting'
                                                    )}`"
                                                    :label="`${$t(
                                                        'devices:model'
                                                    )}*`"
                                                    @keyup="
                                                        setModels(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @input="
                                                        assignModels(
                                                            getDeviceModal.model
                                                        )
                                                    "
                                                    return-object
                                                    item-text="model"
                                                    item-value="_id"
                                                    :items="
                                                        getModelsInput.items
                                                    "
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.model }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.model }}
                                                    </template>
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-database</v-icon
                                                        >
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                            <v-col cols="4"
                                                ><v-text-field
                                                    v-model="
                                                        getDeviceModal.model
                                                            .manufacturer
                                                    "
                                                    readonly
                                                    dense
                                                    :label="
                                                        $t(
                                                            'devices:manufacturer'
                                                        )
                                                    "
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-domain</v-icon
                                                        >
                                                    </template></v-text-field
                                                >
                                            </v-col>
                                            <v-col cols="5"
                                                ><v-text-field
                                                    v-model="
                                                        getDeviceModal.model
                                                            .deviceType
                                                    "
                                                    readonly
                                                    dense
                                                    :label="
                                                        $t('devices:deviceType')
                                                    "
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-collage</v-icon
                                                        >
                                                    </template></v-text-field
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"
                                                ><v-textarea
                                                    v-model="
                                                        getDeviceModal.description
                                                    "
                                                    class="ma-0"
                                                    dense
                                                    rows="1"
                                                    no-resize
                                                    auto-grow
                                                    :label="
                                                        $t(
                                                            'devices:description'
                                                        )
                                                    "
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-text-subject</v-icon
                                                        >
                                                    </template></v-textarea
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"
                                                ><v-select
                                                    v-model="
                                                        getDeviceModal.options
                                                    "
                                                    dense
                                                    item-value="text"
                                                    :items="
                                                        getDeviceModal.model
                                                            ? getDeviceModal
                                                                  .model.options
                                                            : []
                                                    "
                                                    :label="
                                                        $t('devices:options')
                                                    "
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-format-list-text</v-icon
                                                        >
                                                    </template></v-select
                                                ></v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4"
                                                ><v-select
                                                    v-model="
                                                        getDeviceModal.serviceBase
                                                    "
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.serviceBase
                                                    "
                                                    dense
                                                    return-object
                                                    :items="
                                                        getRegistryDictionaries.serviceBase
                                                            ? getRegistryDictionaries.serviceBase
                                                            : []
                                                    "
                                                    :label="`${$t(
                                                        'devices:serviceBase'
                                                    )}*`"
                                                    item-value="field"
                                                    ><template
                                                        v-slot:item="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                    <template
                                                        v-slot:selection="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4"
                                                ><v-select
                                                    v-model="
                                                        getDeviceModal.priority
                                                    "
                                                    :items="
                                                        getRegistryDictionaries.priority
                                                            ? getRegistryDictionaries.priority
                                                            : []
                                                    "
                                                    dense
                                                    :label="
                                                        $t('devices:priority')
                                                    "
                                                    return-object
                                                    item-value="field"
                                                    ><template
                                                        v-slot:item="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                    <template
                                                        v-slot:selection="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-combobox
                                                    dense
                                                    v-model="
                                                        getDeviceModal.deliverer
                                                    "
                                                    :hide-no-data="hideDelivery"
                                                    @click="clearItem()"
                                                    :hint="`${$t(
                                                        'global:startWriting'
                                                    )}`"
                                                    :label="`${$t(
                                                        'devices:delivery'
                                                    )}`"
                                                    @keyup="
                                                        setContacts(
                                                            $event.target.value
                                                        )
                                                    "
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.deliverer
                                                    "
                                                    item-text="name"
                                                    item-value="name"
                                                    :items="
                                                        getContactsInput.items
                                                    "
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.name }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.name }}
                                                    </template>
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-truck</v-icon
                                                        >
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                            <v-col cols="3"
                                                ><v-select
                                                    v-model="
                                                        getDeviceModal.owner
                                                    "
                                                    dense
                                                    :items="
                                                        getRegistryDictionaries.owner
                                                            ? getRegistryDictionaries.owner
                                                            : []
                                                    "
                                                    :label="$t('devices:owner')"
                                                    return-object
                                                    item-value="field"
                                                    ><template
                                                        v-slot:item="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                    <template
                                                        v-slot:selection="data"
                                                    >
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                        >
                                                            <v-chip
                                                                class="mx-2"
                                                                :color="
                                                                    data.item
                                                                        .backgroundColor
                                                                "
                                                                small
                                                                label
                                                                :text-color="
                                                                    data.item
                                                                        .textColor
                                                                        ? data
                                                                              .item
                                                                              .textColor
                                                                        : '#4d4d4d'
                                                                "
                                                            >
                                                                {{
                                                                    data.item
                                                                        .field
                                                                }}
                                                            </v-chip>
                                                        </span>
                                                        <span
                                                            class="truncate-list"
                                                            v-else
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </span>
                                                    </template>
                                                </v-select></v-col
                                            >
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="
                                                        getDeviceModal.purchaseInvoice
                                                    "
                                                    dense
                                                    :label="
                                                        $t(
                                                            'devices:purchaseInvoice'
                                                        )
                                                    "
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-receipt</v-icon
                                                        >
                                                    </template></v-text-field
                                                >
                                            </v-col>
                                            <v-col cols="3">
                                                <v-menu
                                                    v-model="purchaseDate"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getDeviceModal.purchaseDate
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'devices:dateOfPurchase'
                                                                )
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getDeviceModal.purchaseDate = null
                                                            "
                                                            prepend-icon="mdi-calendar-today"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template
                                                                v-slot:prepend
                                                            >
                                                                <v-icon
                                                                    color="iconGray"
                                                                    size="18"
                                                                    >mdi-calendar-today</v-icon
                                                                >
                                                            </template></v-text-field
                                                        >
                                                    </template>

                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getDeviceModal.purchaseDate
                                                        "
                                                        @input="
                                                            purchaseDate = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-menu
                                                    v-model="guaranteeFrom"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getDeviceModal.guaranteeFrom
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'devices:warrantyFrom'
                                                                )
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getDeviceModal.guaranteeFrom = null
                                                            "
                                                            prepend-icon="mdi-calendar-today"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template
                                                                v-slot:prepend
                                                            >
                                                                <v-icon
                                                                    color="iconGray"
                                                                    size="18"
                                                                    >mdi-calendar-today</v-icon
                                                                >
                                                            </template></v-text-field
                                                        >
                                                    </template>

                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getDeviceModal.guaranteeFrom
                                                        "
                                                        @input="
                                                            guaranteeFrom = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-menu
                                                    v-model="guaranteeTo"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getDeviceModal.guaranteeTo
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'devices:warrantyTo'
                                                                )
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getDeviceModal.guaranteeTo = null
                                                            "
                                                            prepend-icon="mdi-calendar-today"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template
                                                                v-slot:prepend
                                                            >
                                                                <v-icon
                                                                    color="iconGray"
                                                                    size="18"
                                                                    >mdi-calendar-today</v-icon
                                                                >
                                                            </template></v-text-field
                                                        >
                                                    </template>

                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getDeviceModal.guaranteeTo
                                                        "
                                                        @input="
                                                            guaranteeTo = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="4">
                                                <v-menu
                                                    v-model="partGuarantee"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getDeviceModal.partGuarantee
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'devices:warrantyParts'
                                                                )
                                                            "
                                                            clearable
                                                            @click:clear="
                                                                getDeviceModal.partGuarantee = null
                                                            "
                                                            prepend-icon="mdi-calendar-today"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template
                                                                v-slot:prepend
                                                            >
                                                                <v-icon
                                                                    color="iconGray"
                                                                    size="18"
                                                                    >mdi-calendar-today</v-icon
                                                                >
                                                            </template></v-text-field
                                                        >
                                                    </template>

                                                    <v-date-picker
                                                        data-cy="datePicker"
                                                        v-model="
                                                            getDeviceModal.partGuarantee
                                                        "
                                                        @input="
                                                            partGuarantee = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        xl="4"
                                        lg="4"
                                        sm="12"
                                        class="mt-5"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                class="client--section"
                                            >
                                                <Separator
                                                    :text="$t('devices:client')"
                                                /> </v-col
                                        ></v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-combobox
                                                    v-model="
                                                        getDeviceModal.client
                                                    "
                                                    @input="assignBranch()"
                                                    dense
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.client
                                                    "
                                                    :label="`${$t(
                                                        'devices:name'
                                                    )}*`"
                                                    :hint="`${$t(
                                                        'global:startWriting'
                                                    )}`"
                                                    @click="clearItem()"
                                                    @keyup="
                                                        setContacts(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @
                                                    return-object
                                                    item-text="name"
                                                    item-value="_id"
                                                    :items="
                                                        getContactsInput.items
                                                    "
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.name }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.name }}
                                                    </template>
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-account</v-icon
                                                        > </template
                                                    ><template
                                                        v-slot:append-outer
                                                    >
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    icon
                                                                    color="secondary"
                                                                    @click="
                                                                        modalContact.open = true
                                                                    "
                                                                    small
                                                                    v-on="on"
                                                                >
                                                                    <v-icon
                                                                        size="15"
                                                                        >mdi-briefcase-plus</v-icon
                                                                    ></v-btn
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'devices:addClient'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select
                                                    v-model="
                                                        getDeviceModal.branch
                                                    "
                                                    :error-messages="
                                                        getDeviceError.stepOne
                                                            .fields.branch
                                                    "
                                                    dense
                                                    item-value="_id"
                                                    :items="branches"
                                                    :label="`${$t(
                                                        'devices:address'
                                                    )}*`"
                                                >
                                                    <template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'city',
                                                                ''
                                                            )
                                                        }},
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'address',
                                                                ''
                                                            )
                                                        }}
                                                        <span class="mx-2"
                                                            >-</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'postCode',
                                                                ''
                                                            )
                                                        }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'city',
                                                                ''
                                                            )
                                                        }},
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'address',
                                                                ''
                                                            )
                                                        }}
                                                        <span class="mx-2"
                                                            >-</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'postCode',
                                                                ''
                                                            )
                                                        }}
                                                    </template>
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-home-city-outline</v-icon
                                                        > </template
                                                    ><template
                                                        v-slot:append-outer
                                                    >
                                                        <!-- <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="
                                                                        $emit(
                                                                            'view-map',
                                                                            getDeviceModal
                                                                                .branch
                                                                                .location
                                                                        )
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    <v-icon
                                                                        color="error"
                                                                        size="18"
                                                                        >mdi-map-marker</v-icon
                                                                    ></v-btn
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'devices:location'
                                                                )
                                                            }}</span>
                                                        </v-tooltip> -->
                                                        <v-tooltip
                                                            bottom
                                                            v-if="
                                                                getDeviceModal.client
                                                            "
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    icon
                                                                    @click="
                                                                        modalBranches.open = true
                                                                    "
                                                                    small
                                                                    color="secondary"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon
                                                                        size="18"
                                                                        >mdi-home-plus</v-icon
                                                                    ></v-btn
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'devices:addBranches'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template></v-select
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="
                                                        getDeviceModal.floor
                                                    "
                                                    dense
                                                    :label="$t('devices:floor')"
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-home-floor-1</v-icon
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </v-col></v-row
                                        >
                                        <v-row>
                                            <v-col cols="12"
                                                ><v-select
                                                    v-model="
                                                        getDeviceModal.representatives
                                                    "
                                                    dense
                                                    item-value="_id"
                                                    :label="
                                                        $t(
                                                            'devices:contactPerson'
                                                        )
                                                    "
                                                    :items="representatives"
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'name',
                                                                ''
                                                            )
                                                        }}

                                                        {{
                                                            $get(
                                                                data.item,
                                                                'lastname',
                                                                ''
                                                            )
                                                        }}
                                                        <span
                                                            v-if="
                                                                data.item.email
                                                            "
                                                            class="mx-2"
                                                            >•</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'email',
                                                                ''
                                                            )
                                                        }}
                                                        <span
                                                            v-if="
                                                                data.item
                                                                    .phoneNumber
                                                            "
                                                            class="mx-2"
                                                            >•</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'phoneNumber',
                                                                ''
                                                            )
                                                        }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'name',
                                                                ''
                                                            )
                                                        }}

                                                        {{
                                                            $get(
                                                                data.item,
                                                                'lastname',
                                                                ''
                                                            )
                                                        }}
                                                        <span
                                                            v-if="
                                                                data.item.email
                                                            "
                                                            class="mx-2"
                                                            >•</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'email',
                                                                ''
                                                            )
                                                        }}
                                                        <span
                                                            v-if="
                                                                data.item.email
                                                            "
                                                            class="mx-2"
                                                            >•</span
                                                        >
                                                        {{
                                                            $get(
                                                                data.item,
                                                                'phoneNumber',
                                                                ''
                                                            )
                                                        }}
                                                    </template>
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-phone</v-icon
                                                        > </template
                                                    ><template
                                                        v-slot:append-outer
                                                    >
                                                        <v-tooltip
                                                            bottom
                                                            v-if="
                                                                getDeviceModal.client
                                                            "
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="
                                                                        modalRepresentative.open = true
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    <v-icon
                                                                        size="18"
                                                                        color="secondary"
                                                                        >mdi-account-plus</v-icon
                                                                    ></v-btn
                                                                >
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'devices:addRepresentatives'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template></v-select
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="7"
                                        lg="7"
                                        sm="12"
                                        class="pb-0 padding-r"
                                    >
                                        <!-- <v-row>
                                            <v-col cols="6"
                                                ><v-text-field
                                                    v-model="
                                                        getDeviceModal.asset
                                                    "
                                                    dense
                                                    :label="$t('devices:asset')"
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-fridge</v-icon
                                                        >
                                                    </template></v-text-field
                                                ></v-col
                                            >
                                        </v-row> -->
                                        <v-row>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    class="ma-0"
                                                    v-model="
                                                        getDeviceModal.monitoringDevice
                                                    "
                                                    color="secondary"
                                                    :label="
                                                        $t(
                                                            'devices:monitoringDevice'
                                                        )
                                                    "
                                                >
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="5"
                                        xl="5"
                                        lg="5"
                                        sm="12"
                                        class="pb-0"
                                    >
                                        <!-- <v-row>
                                            <v-col cols="12"
                                                ><v-text-field
                                                    v-model="getDeviceModal.OSP"
                                                    dense
                                                    :label="$t('devices:OSP')"
                                                    ><template v-slot:prepend>
                                                        <v-icon
                                                            color="iconGray"
                                                            size="18"
                                                            >mdi-file-outline</v-icon
                                                        >
                                                    </template></v-text-field
                                                ></v-col
                                            >
                                        </v-row> -->
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('devices:moreAboutDevice')
                                            "
                                        /> </v-col
                                ></v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        xl="12"
                                        lg="12"
                                        sm="12"
                                        class="d-flex flex-row align-center ml-4"
                                    >
                                        <!-- <v-row>
                                            <v-col
                                                cols="12"
                                                lg="4"
                                                xl="4"
                                                md="6"
                                                sm="8"
                                                class="d-flex flex-column align-center"
                                            >
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'devices:servicePackage'
                                                        )
                                                    "
                                                    :text="''"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'devices:dedicatedPrice'
                                                        )
                                                    "
                                                    :isCheckbox="true"
                                                    :checkboxValue="
                                                        checkboxValue
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                >
                                                    <v-checkbox
                                                        slot="checkbox"
                                                        v-model="
                                                            getDeviceModal.dedicatedPrice
                                                        "
                                                        :readonly="false"
                                                        color="secondary"
                                                        class="summaryLine__checkbox"
                                                    ></v-checkbox>
                                                </SummaryLine>
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'devices:packagePrices'
                                                        )
                                                    "
                                                    :text="'standardowa'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />

                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('devices:surveyCost')
                                                    "
                                                    :text="'100.00 PLN'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('devices:repairCost')
                                                    "
                                                    :text="'120.00 PLN'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'devices:installationCost'
                                                        )
                                                    "
                                                    :text="'150.00 PLN'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'devices:diagnosticsCost'
                                                        )
                                                    "
                                                    :text="'110.00 PLN'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('devices:ITservice')
                                                    "
                                                    :text="'120.00 PLN'"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                lg="2"
                                                md="3"
                                                sm="4"
                                                v-if="
                                                    getDeviceModal.dedicatedPrice
                                                "
                                                class="dedicated--prices"
                                            >
                                                <div class="grey--text">
                                                    {{
                                                        $t('devices:dedicated')
                                                    }}
                                                </div>
                                                <money-field
                                                    class="margin--input"
                                                    :disabled="false"
                                                    :readonly="false"
                                                    v-model="
                                                        getDeviceModal.survey
                                                            .dedicated
                                                    "
                                                ></money-field>
                                                <money-field
                                                    :disabled="false"
                                                    :readonly="false"
                                                    class="margin--input"
                                                    v-model="
                                                        getDeviceModal.repair
                                                            .dedicated
                                                    "
                                                ></money-field>
                                                <money-field
                                                    :disabled="false"
                                                    :readonly="false"
                                                    class="margin--input"
                                                    v-model="
                                                        getDeviceModal
                                                            .installation
                                                            .dedicated
                                                    "
                                                ></money-field>
                                                <money-field
                                                    :disabled="false"
                                                    :readonly="false"
                                                    class="margin--input"
                                                    v-model="
                                                        getDeviceModal
                                                            .diagnostics
                                                            .dedicated
                                                    "
                                                ></money-field>
                                                <money-field
                                                    :disabled="false"
                                                    :readonly="false"
                                                    class="margin--input"
                                                    v-model="
                                                        getDeviceModal.itService
                                                            .dedicated
                                                    "
                                                ></money-field>
                                            </v-col>
                                        </v-row> -->
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="mt-5 margin--comments"
                                    >
                                        <v-textarea
                                            dense
                                            rows="1"
                                            v-model="getDeviceModal.comment"
                                            no-resize
                                            auto-grow
                                            :label="$t('devices:comments')"
                                            ><template v-slot:prepend>
                                                <v-icon
                                                    color="iconGray"
                                                    size="18"
                                                    >mdi-alert-octagon</v-icon
                                                >
                                            </template></v-textarea
                                        >
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AEContentContact from './../../../../components/Registries/Contacts/Modal/Content'
import ButtonsContact from './../../../../components/Registries/Contacts/Modal/Buttons'
import AEContentBranches from './../../../../components/Registries/Contacts/Tabs/Branches/Modal/Content'
import ButtonsBranches from './../../../../components/Registries/Contacts/Tabs/Branches/Modal/Buttons'
import AEContentRepresentative from './../../../../components/Registries/Contacts/Tabs/Representatives/Modal/Content'
import ButtonsRepresentative from './../../../../components/Registries/Contacts/Tabs/Representatives/Modal/Buttons'
import MoneyField from '../../../Global/MoneyField/MoneyField.vue'

export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            // hideModels: false,
            purchaseDate: '',
            guaranteeFrom: '',
            hideDelivery: true,
            guaranteeTo: '',
            partGuarantee: '',
            options: [],
            modalContact: {
                open: false,
            },
            modalBranches: {
                open: false,
            },
            checkboxValue: false,
            modalRepresentative: {
                open: false,
            },
            disabled: false,
            editable: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchModelsInputs',
            'fetchContactsInputs',
            'fetchBranchesTable',
            'createRepresentative',
            'createBranch',
            'createContact',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations([
            'setModelsInput',
            'setContactsInput',
            'clearRepresentativesErrors',
            'clearRepresentsativeModal',
            'clearBranchErrors',
            'clearBranchModal',
        ]),
        closeModalContact() {
            this.modalContact.open = false
        },
        assignModels(models) {
            if (models) {
                this.getDeviceModal.manufacturer = models.manufacturer
                this.getDeviceModal.deviceType = models.deviceType
                this.options = models.options
            } else {
                this.getDeviceModal.manufacturer = ''
                this.getDeviceModal.deviceType = ''
            }
        },
        assignBranch() {
            this.getDeviceModal.branch = this.getDeviceModal.client
                ? this.getDeviceModal.client.branches[0]
                : []
        },
        clearItem() {
            this.getContactsInput.items = []
        },
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideDelivery = false
            } else {
                this.getContactsInput.items = []
                this.hideDelivery = true
            }
        },
        closeModalBranches() {
            this.modalBranches.open = false
            this.clearBranchErrors()
            this.clearBranchModal()
        },
        closeModalRepresentative() {
            this.modalRepresentative.open = false
            this.clearRepresentativesErrors()
            this.clearRepresentsativeModal()
        },
        async addNewContact() {
            let success = await this.createContact()
            if (success) {
                let contactSuccess = await this.fetchContactsInputs(
                    '-createdAt'
                )
                if (contactSuccess) {
                    this.getDeviceModal.branch = this.getContactsInput.items[0].branches[0]
                    this.getDeviceModal.client = this.getContactsInput.items[0]
                    this.closeModalContact()
                }
            }
        },
        async addNewRepresentative() {
            if (this.getDeviceModal.client) {
                let success = await this.createRepresentative({
                    id: this.getDeviceModal.client._id,
                })
                if (success) {
                    this.modalRepresentative.open = false
                    this.clearRepresentativesErrors()
                    this.clearRepresentsativeModal()
                    await this.fetchRepresentativesInput({
                        id: this.getDeviceModal.client._id,
                        limit: 300,
                        sort: '-createdAt',
                    })

                    this.getDeviceModal.client.representatives = this.getRepresentativeInput.items
                    this.getDeviceModal.representatives = this.getRepresentativeInput.items[0]
                }
            }
        },
        async addNewBranches() {
            if (this.getDeviceModal.client) {
                let success = await this.createBranch({
                    id: this.getDeviceModal.client._id,
                })
                if (success) {
                    this.modalBranches.open = false
                    this.clearBranchErrors()
                    this.clearBranchModal()
                    await void this.fetchBranchesTable(
                        this.getDeviceModal.client._id
                    )
                    this.getDeviceModal.client.branches = this.getBranchesTable.items
                    this.getDeviceModal.branch = this.getBranchesTable.items[0]
                }
            }
        },
        setModels(search) {
            if (search.length >= 3) {
                this.setModelsInput({ search })
                this.fetchModelsInputs()
            } else {
                this.getModelsInput.items = []
            }
        },
    },
    components: {
        AEContentContact,
        ButtonsContact,
        AEContentBranches,
        ButtonsBranches,
        AEContentRepresentative,
        ButtonsRepresentative,
    },
    computed: {
        ...mapGetters([
            'getRegistryDictionaries',
            'getDeviceStep',
            'getDeviceModal',
            'getDeviceError',
            'getModelsInput',
            'getContactsInput',
            'getBranchesTable',
            'getRepresentativeInput',
            'getDevicesRegistry',
            'getProfileDetails',
        ]),
        editSn: {
            get() {
                if (this.action == 'edit') {
                    if (
                        this.getDevicesRegistry.currentMenager._id ==
                        this.getProfileDetails._id
                    )
                        return false
                    else return true
                }
            },
        },
        branches: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client?.branches?.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    )
                else return []
            },
        },
        representatives: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client?.representatives?.sort(
                        (a, b) => (a.lastname > b.lastname ? 1 : -1)
                    )
                else return []
            },
        },
    },
}
</script>
<style lang="sass" scoped>
.margin--input
    margin-top: 7px !important
.padding-r
    padding-right: 25px !important
.dedicated--prices
    font-size: 14px !important
    justify-content: space-between
    height: 225px,
    padding: 0 !important
    margin-left: -25px
    margin-top: 87px
    color: #9e9e9e !important
.client--section
    margin-top: 28px
.summaryLine--font
    font-size: 14px !important
</style>

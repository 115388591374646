<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createDevice', 'updateDevice']),
        async save() {
            this.disabled = true
            let success = await this.createDevice()
            if (success){
                this.$emit('closeModal')
                if(this.$route.path!=='/devices') this.$router.push(`/devices/${success._id}`)
            }
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateDevice({
                id: this.$route.params.id,
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>

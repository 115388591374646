var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getDeviceStep.step),callback:function ($$v) {_vm.$set(_vm.getDeviceStep, "step", $$v)},expression:"getDeviceStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getDeviceError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('cars:carInformation')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getDeviceError.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('cars:carExtraInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('Modal',{attrs:{"title":_vm.$t('global:addition'),"open":_vm.modalContact.open},on:{"close":_vm.closeModalContact},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.addNewContact()},"closeModal":_vm.closeModalContact}},[_vm._v(" "+_vm._s(_vm.$t('global:save'))+" ")])]},proxy:true}])},[_c('AEContentContact',{attrs:{"slot":"AEContent"},slot:"AEContent"})],1),_c('Modal',{attrs:{"title":_vm.$t('global:addition'),"open":_vm.modalBranches.open},on:{"close":_vm.closeModalBranches},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.addNewBranches()}}},[_vm._v(" "+_vm._s(_vm.$t('global:save'))+" ")])]},proxy:true}])},[_c('AEContentBranches',{attrs:{"slot":"AEContent"},slot:"AEContent"})],1),_c('Modal',{attrs:{"title":_vm.$t('global:addition'),"open":_vm.modalRepresentative.open},on:{"close":_vm.closeModalRepresentative},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.addNewRepresentative()}}},[_vm._v(" "+_vm._s(_vm.$t('global:save'))+" ")])]},proxy:true}])},[_c('AEContentRepresentative',{attrs:{"slot":"AEContent"},slot:"AEContent"})],1),_c('v-row',[_c('v-col',{staticClass:"padding-r",attrs:{"cols":"8","xl":"8","lg":"8","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.editSn,"dense":"","error-messages":_vm.getDeviceError.stepOne
                                                        .fields.sn,"label":`${_vm.$t(
                                                    'devices:sn'
                                                )}*`},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-barcode")])]},proxy:true}]),model:{value:(_vm.getDeviceModal.sn),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "sn", $$v)},expression:"getDeviceModal.sn"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4","md":"4"}}),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getDeviceError.stepOne
                                                        .fields.identificationNumber,"label":`${_vm.$t(
                                                    'devices:identificationNumber'
                                                )}*`},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-identifier")])]},proxy:true}]),model:{value:(_vm.getDeviceModal.identificationNumber),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "identificationNumber", $$v)},expression:"getDeviceModal.identificationNumber"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:device')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"dense":"","error-messages":_vm.getDeviceError.stepOne
                                                        .fields.model,"hint":`${_vm.$t(
                                                    'global:startWriting'
                                                )}`,"label":`${_vm.$t(
                                                    'devices:model'
                                                )}*`,"return-object":"","item-text":"model","item-value":"_id","items":_vm.getModelsInput.items},on:{"keyup":function($event){return _vm.setModels(
                                                        $event.target.value
                                                    )},"input":function($event){return _vm.assignModels(
                                                        _vm.getDeviceModal.model
                                                    )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.model)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.model)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-database")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.model
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "model", $$v)},expression:"\n                                                    getDeviceModal.model\n                                                "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","label":_vm.$t(
                                                        'devices:manufacturer'
                                                    )},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-domain")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.model
                                                        .manufacturer
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal.model
                                                        , "manufacturer", $$v)},expression:"\n                                                    getDeviceModal.model\n                                                        .manufacturer\n                                                "}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","label":_vm.$t('devices:deviceType')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-collage")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.model
                                                        .deviceType
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal.model
                                                        , "deviceType", $$v)},expression:"\n                                                    getDeviceModal.model\n                                                        .deviceType\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"ma-0",attrs:{"dense":"","rows":"1","no-resize":"","auto-grow":"","label":_vm.$t(
                                                        'devices:description'
                                                    )},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-text-subject")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.description
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "description", $$v)},expression:"\n                                                    getDeviceModal.description\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","item-value":"text","items":_vm.getDeviceModal.model
                                                        ? _vm.getDeviceModal
                                                              .model.options
                                                        : [],"label":_vm.$t('devices:options')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-format-list-text")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.options
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "options", $$v)},expression:"\n                                                    getDeviceModal.options\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"error-messages":_vm.getDeviceError.stepOne
                                                        .fields.serviceBase,"dense":"","return-object":"","items":_vm.getRegistryDictionaries.serviceBase
                                                        ? _vm.getRegistryDictionaries.serviceBase
                                                        : [],"label":`${_vm.$t(
                                                    'devices:serviceBase'
                                                )}*`,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                    _vm.getDeviceModal.serviceBase
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "serviceBase", $$v)},expression:"\n                                                    getDeviceModal.serviceBase\n                                                "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.priority
                                                        ? _vm.getRegistryDictionaries.priority
                                                        : [],"dense":"","label":_vm.$t('devices:priority'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                    _vm.getDeviceModal.priority
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "priority", $$v)},expression:"\n                                                    getDeviceModal.priority\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"dense":"","hide-no-data":_vm.hideDelivery,"hint":`${_vm.$t(
                                                    'global:startWriting'
                                                )}`,"label":`${_vm.$t(
                                                    'devices:delivery'
                                                )}`,"error-messages":_vm.getDeviceError.stepOne
                                                        .fields.deliverer,"item-text":"name","item-value":"name","items":_vm.getContactsInput.items},on:{"click":function($event){return _vm.clearItem()},"keyup":function($event){return _vm.setContacts(
                                                        $event.target.value
                                                    )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-truck")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.deliverer
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "deliverer", $$v)},expression:"\n                                                    getDeviceModal.deliverer\n                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.getRegistryDictionaries.owner
                                                        ? _vm.getRegistryDictionaries.owner
                                                        : [],"label":_vm.$t('devices:owner'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                            data.item
                                                                .backgroundColor
                                                        )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                    .backgroundColor,"small":"","label":"","text-color":data.item
                                                                    .textColor
                                                                    ? data
                                                                          .item
                                                                          .textColor
                                                                    : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item .field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                    _vm.getDeviceModal.owner
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "owner", $$v)},expression:"\n                                                    getDeviceModal.owner\n                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                        'devices:purchaseInvoice'
                                                    )},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-receipt")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.purchaseInvoice
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "purchaseInvoice", $$v)},expression:"\n                                                    getDeviceModal.purchaseInvoice\n                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'devices:dateOfPurchase'
                                                            ),"clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"click:clear":function($event){_vm.getDeviceModal.purchaseDate = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(
                                                            _vm.getDeviceModal.purchaseDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "purchaseDate", $$v)},expression:"\n                                                            getDeviceModal.purchaseDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.purchaseDate),callback:function ($$v) {_vm.purchaseDate=$$v},expression:"purchaseDate"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.purchaseDate = false}},model:{value:(
                                                        _vm.getDeviceModal.purchaseDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "purchaseDate", $$v)},expression:"\n                                                        getDeviceModal.purchaseDate\n                                                    "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'devices:warrantyFrom'
                                                            ),"clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"click:clear":function($event){_vm.getDeviceModal.guaranteeFrom = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(
                                                            _vm.getDeviceModal.guaranteeFrom
                                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "guaranteeFrom", $$v)},expression:"\n                                                            getDeviceModal.guaranteeFrom\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.guaranteeFrom),callback:function ($$v) {_vm.guaranteeFrom=$$v},expression:"guaranteeFrom"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.guaranteeFrom = false}},model:{value:(
                                                        _vm.getDeviceModal.guaranteeFrom
                                                    ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "guaranteeFrom", $$v)},expression:"\n                                                        getDeviceModal.guaranteeFrom\n                                                    "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'devices:warrantyTo'
                                                            ),"clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"click:clear":function($event){_vm.getDeviceModal.guaranteeTo = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(
                                                            _vm.getDeviceModal.guaranteeTo
                                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "guaranteeTo", $$v)},expression:"\n                                                            getDeviceModal.guaranteeTo\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.guaranteeTo),callback:function ($$v) {_vm.guaranteeTo=$$v},expression:"guaranteeTo"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.guaranteeTo = false}},model:{value:(
                                                        _vm.getDeviceModal.guaranteeTo
                                                    ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "guaranteeTo", $$v)},expression:"\n                                                        getDeviceModal.guaranteeTo\n                                                    "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'devices:warrantyParts'
                                                            ),"clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"click:clear":function($event){_vm.getDeviceModal.partGuarantee = null}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(
                                                            _vm.getDeviceModal.partGuarantee
                                                        ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "partGuarantee", $$v)},expression:"\n                                                            getDeviceModal.partGuarantee\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.partGuarantee),callback:function ($$v) {_vm.partGuarantee=$$v},expression:"partGuarantee"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.partGuarantee = false}},model:{value:(
                                                        _vm.getDeviceModal.partGuarantee
                                                    ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "partGuarantee", $$v)},expression:"\n                                                        getDeviceModal.partGuarantee\n                                                    "}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"4","xl":"4","lg":"4","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"client--section",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:client')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"dense":"","error-messages":_vm.getDeviceError.stepOne
                                                        .fields.client,"label":`${_vm.$t(
                                                    'devices:name'
                                                )}*`,"hint":`${_vm.$t(
                                                    'global:startWriting'
                                                )}`,"return-object":"","item-text":"name","item-value":"_id","items":_vm.getContactsInput.items},on:{"input":function($event){return _vm.assignBranch()},"click":function($event){return _vm.clearItem()},"keyup":function($event){return _vm.setContacts(
                                                        $event.target.value
                                                    )},"":function($event){}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-account")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on,
                                                            }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","small":""},on:{"click":function($event){_vm.modalContact.open = true}}},on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-briefcase-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( 'devices:addClient' )))])])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.client
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "client", $$v)},expression:"\n                                                    getDeviceModal.client\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"error-messages":_vm.getDeviceError.stepOne
                                                        .fields.branch,"dense":"","item-value":"_id","items":_vm.branches,"label":`${_vm.$t(
                                                    'devices:address'
                                                )}*`},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$get( data.item, 'city', '' ))+", "+_vm._s(_vm.$get( data.item, 'address', '' ))+" "),_c('span',{staticClass:"mx-2"},[_vm._v("-")]),_vm._v(" "+_vm._s(_vm.$get( data.item, 'postCode', '' ))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$get( data.item, 'city', '' ))+", "+_vm._s(_vm.$get( data.item, 'address', '' ))+" "),_c('span',{staticClass:"mx-2"},[_vm._v("-")]),_vm._v(" "+_vm._s(_vm.$get( data.item, 'postCode', '' ))+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-home-city-outline")])]},proxy:true},{key:"append-outer",fn:function(){return [(
                                                            _vm.getDeviceModal.client
                                                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on,
                                                            }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"secondary"},on:{"click":function($event){_vm.modalBranches.open = true}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-home-plus")])],1)]}}],null,false,1404881627)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'devices:addBranches' )))])]):_vm._e()]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.branch
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "branch", $$v)},expression:"\n                                                    getDeviceModal.branch\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('devices:floor')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-home-floor-1")])]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.floor
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "floor", $$v)},expression:"\n                                                    getDeviceModal.floor\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","item-value":"_id","label":_vm.$t(
                                                        'devices:contactPerson'
                                                    ),"items":_vm.representatives},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$get( data.item, 'name', '' ))+" "+_vm._s(_vm.$get( data.item, 'lastname', '' ))+" "),(
                                                            data.item.email
                                                        )?_c('span',{staticClass:"mx-2"},[_vm._v("•")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$get( data.item, 'email', '' ))+" "),(
                                                            data.item
                                                                .phoneNumber
                                                        )?_c('span',{staticClass:"mx-2"},[_vm._v("•")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$get( data.item, 'phoneNumber', '' ))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$get( data.item, 'name', '' ))+" "+_vm._s(_vm.$get( data.item, 'lastname', '' ))+" "),(
                                                            data.item.email
                                                        )?_c('span',{staticClass:"mx-2"},[_vm._v("•")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$get( data.item, 'email', '' ))+" "),(
                                                            data.item.email
                                                        )?_c('span',{staticClass:"mx-2"},[_vm._v("•")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$get( data.item, 'phoneNumber', '' ))+" ")]}},{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-phone")])]},proxy:true},{key:"append-outer",fn:function(){return [(
                                                            _vm.getDeviceModal.client
                                                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on,
                                                            }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.modalRepresentative.open = true}}},on),[_c('v-icon',{attrs:{"size":"18","color":"secondary"}},[_vm._v("mdi-account-plus")])],1)]}}],null,false,2038810500)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'devices:addRepresentatives' )))])]):_vm._e()]},proxy:true}]),model:{value:(
                                                    _vm.getDeviceModal.representatives
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "representatives", $$v)},expression:"\n                                                    getDeviceModal.representatives\n                                                "}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0 padding-r",attrs:{"cols":"7","lg":"7","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"color":"secondary","label":_vm.$t(
                                                        'devices:monitoringDevice'
                                                    )},model:{value:(
                                                    _vm.getDeviceModal.monitoringDevice
                                                ),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "monitoringDevice", $$v)},expression:"\n                                                    getDeviceModal.monitoringDevice\n                                                "}})],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5","xl":"5","lg":"5","sm":"12"}})],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('devices:moreAboutDevice')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center ml-4",attrs:{"cols":"12","md":"12","xl":"12","lg":"12","sm":"12"}})],1),_c('v-row',[_c('v-col',{staticClass:"mt-5 margin--comments",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"1","no-resize":"","auto-grow":"","label":_vm.$t('devices:comments')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"iconGray","size":"18"}},[_vm._v("mdi-alert-octagon")])]},proxy:true}]),model:{value:(_vm.getDeviceModal.comment),callback:function ($$v) {_vm.$set(_vm.getDeviceModal, "comment", $$v)},expression:"getDeviceModal.comment"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }